$(function () {
    /* Анимация при скролле */
    new WOW({offset: 50}).init();

  /* Mobile menu */
  const menuButton = document.getElementById('pull');
  const header = document.getElementById('header');

  menuButton.addEventListener('click', function() {
    if (!this.classList.contains('active')) {
      this.classList.add('active');
      header.classList.add('active'); 
    } else {
      this.classList.remove('active');
      header.classList.remove('active'); 
    }
  });

  /* Smooth scroll */
  $("a").on('click', function() {
    var _href = $(this).attr("href");
    if ( _href.substring(0,1) === '#') {
        $("html, body").animate({scrollTop: $(_href).offset().top+"px"});
        return false;
    };
  });

    /* Shedule */
    /*if ($('#chart').length) {
        function buildChart(jsonData) {
            const colors = [
                "rgba(66,133,244,0.5)",
                "rgba(234,67,53,0.5)",
                "rgba(251,188,4,0.5)",
                "rgba(52,168,83,0.5)",
                "rgba(255,109,1,0.5)",
                "rgba(70,189,198,0.5)",
                "rgba(123,170,247,0.5)",
                "rgba(240,123,114,0.5)",
                "rgba(255,229,153,0.5)",
                "rgba(113,194,135,0.5)",
                "rgba(255,153,77,0.5)",
            ];
        
            const borderColors = [
                "rgba(66,133,244,0.9)",
                "rgba(234,67,53,0.9)",
                "rgba(251,188,4,0.9)",
                "rgba(52,168,83,0.9)",
                "rgba(255,109,1,0.9)",
                "rgba(70,189,198,0.9)",
                "rgba(123,170,247,0.9)",
                "rgba(240,123,114,0.9)",
                "rgba(255,229,153,0.9)",
                "rgba(113,194,135,0.9)",
                "rgba(255,153,77,0.9)",
            ]
        
            const datasets = jsonData.map(function (item, index) {
            return {
                label: item.title,
                data: Object.keys(item).slice(1).map(function (month) {
                    return item[month];
                }),
                backgroundColor: colors[index],
                borderColor: borderColors[index],
                borderWidth: 2,
                fill: true,
            };
            });
        
            const labels = Object.keys(jsonData[0]).slice(1);
        
            Chart.defaults.elements.point.radius = 0;
            const myChart = new Chart('chart', {
                type: 'line',
                data: {
                    labels: labels,
                    datasets: datasets
                },
                options: {
                    maintainAspectRatio: false,
                    interaction: {
                        intersect: false,
                    },
                    scales: {
                        x: {
                            title: {
                                display: true,
                                text: 'Month',
                                color: '#fff',
                                padding: 10
                            },
                            ticks: {
                                color: "#fff",
                            },
                        },
                        y: {
                            stacked: true,
                            grid: {
                                drawBorder: false,
                                color: '#333',
                            },
                            title: {
                                display: true,
                                text: 'Amount of Tokens',
                                color: '#fff',
                                padding: 10
                            },
                            ticks: {
                                color: "#fff",
                            },
                        }
                    },
                    plugins: {
                        filler: {
                            propagate: false
                        },
                        'samples-filler-analyser': {
                            target: 'chart-analyser'
                        },
                        legend: {
                            position: 'bottom',
                            labels: {
                                padding: 20,
                                color: '#fff',
                                boxWidth: 14,
                                boxHeight: 14,
                                font: {
                                    size: 13
                                }
                            }
                        },
                    },
                    arc: {
                        backgroundColor: 'red'
                    }
                }
            });
        }
    
        $(function () {
            const request = new XMLHttpRequest();
            request.open('GET', 'data/release.json', true);
            request.responseType = 'blob';
            request.onload = function () {
                const reader = new FileReader();
                reader.readAsText(request.response);
                reader.onload = function (e) {
                    buildChart(JSON.parse(e.target.result));
                };
            };
            request.send();
        });
    }*/
  

  /* Slider Followers */
	$('#followers').slick({
    slidesToShow: 1,
    slidesToScroll: 1,
    variableWidth: true,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 5000,
    arrows: false,
    dots: false,
    appendArrows: '[data-arrows]',
    prevArrow: '<button type="button" class="slick-prev"></button>',
    nextArrow: '<button type="button" class="slick-next"></button>',
    fade: false,
    speed: 1000,
    pauseOnHover: false,
    swipeToSlide: true,
    zIndex: 100,
    mobileFirst: true,
	});

  /* Slider Roadmap */
  $('#roadmap-slider').slick({
    slidesToShow: 1,
    slidesToScroll: 1,
    variableWidth: true,
    infinite: false,
    autoplay: false,
    autoplaySpeed: 5000,
    arrows: true,
    dots: false,
    appendArrows: '#roadmap-arrows',
    prevArrow: '<button type="button" class="slick-prev"></button>',
    nextArrow: '<button type="button" class="slick-next "></button>',
    fade: false,
    speed: 1000,
    pauseOnHover: false,
    swipeToSlide: true,
    zIndex: 100,
    mobileFirst: true,
	});

    /* Slider Benefits */
  $('#benefits').slick({
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 1,
    infinite: false,
    autoplay: false,
    autoplaySpeed: 5000,
    arrows: true,
    dots: false,
    appendArrows: '#benefits',
    prevArrow: '<button type="button" class="slick-prev  benefits__prev-arrow"></button>',
    nextArrow: '<button type="button" class="slick-next benefits__next-arrow"></button>',
    fade: false,
    speed: 1000,
    pauseOnHover: false,
    swipeToSlide: true,
    swipe: true,
    zIndex: 100,
    mobileFirst: true,
    responsive: [
        {
            breakpoint: 767,
            settings: {
                slidesToShow: 2,
            }
        },
        {
            breakpoint: 889,
            settings: {
                slidesToShow: 3,
            }
        },
        {
            breakpoint: 1199,
            settings: {
                slidesToShow: 3,
                swipe: false,
            }
        }
    ]
	});

    /* Turn off the spinner in the video */
    $('.video__video').on('playing', function() {
        $('.video__spinner-wrapper').addClass('disabled');
    });

    /* Табы */
	const activeTabs = function (navBlock, tabsBlock) {
		$(navBlock).easytabs({
			updateHash: false,
			animate: false,
			panelContext: $(tabsBlock),
			defaultTab: '.js-default'
		});
	};
	activeTabs('#tabs-nav', '#tabs');

    /* Кнопка копии */
    const clipboard = new ClipboardJS('.js-copy');

    /* Сокращение текста адреса */
    $('.js-address').each(function() {
        const address = $(this).find('[data-address]').val()
        const shortAdress = `${address.slice(0, 5)}...${address.slice(-3)}`

        $(this).find('[data-shortAddress]').text(shortAdress)
    })

    /* Стилизация селекта */
	$('.js-select').select2({
		width: '100%',
		dropdownAutoWidth: true,
		minimumResultsForSearch: Infinity,
		templateResult: formatSelect,
		templateSelection: formatSelect
	});

	function formatSelect (opt) {
		
		if (!opt.id) {
			return opt.text;
		}

		const optimage = $(opt.element).data('image');
  
		if(!optimage){
			return opt.text;
		} else {
			const $opt = $(
			'<span class="select2-selection__result"><img src="' + optimage + '" class="select2-selection__flag" />' +  $(opt.element).text() + '</span>'
			);
			return $opt;
		}
	}

    /* Таймер */
    const daiDeadline = '2022-11-10';
    const saleDeadline = '2022-11-15';
    
    function startTimer(endtime, idTimer) {
        const daysBlock = $(idTimer).find('[data-days]')
        const hoursBlock = $(idTimer).find('[data-hours]')
        const minutesBlock = $(idTimer).find('[data-minutes]')

        const timeInterval = setInterval(updateClock, 1000)

        updateClock()

        function updateClock() {
            const totalTime = Date.parse(endtime) - new Date()
            const days = Math.floor(totalTime / (1000 * 60 * 60 * 24))
            const hours = Math.floor((totalTime / (1000 * 60 * 60) % 24))
            const minutes = Math.floor((totalTime / 1000 / 60) % 60)

            daysBlock.text(days)
            hoursBlock.text(hours)
            minutesBlock.text(minutes)

            if (totalTime <= 0) {
                clearInterval(timeInterval)
                daysBlock.text(0)
                hoursBlock.text(0)
                minutesBlock.text(0)
            }
        }
    }

    startTimer(daiDeadline, '#daiTimer')
    startTimer(saleDeadline, '#saleTimer')

    /* FAQ */
    $('.presale__item').on('click', function() {
        $(this).toggleClass('active')
    });
});
